<template>
  <section class="section home">
    <div class="container">

      <div v-if="error" class="notification is-danger">{{ error }}</div>
      <Wizard v-if="survey" :existing-survey="survey" @save="saveProgress"/>

    </div>
  </section>
</template>

<script>
import Wizard from '../components/Wizard.vue'

export default {
  components: { Wizard },
  data () {
    return {
      survey: null,
      error: null
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    }
  },
  async mounted () {
    this.error = null
    try {
      const doc = await this.$firestore.collection('surveys').doc(this.id).get()
      if (!doc.exists) {
        this.error = "Survey not found with this ID"
        return
      }
      this.survey = doc.data()
    } catch (e) {
      this.error = e.message
    }
  },
  methods: {
    async saveProgress (survey) {
      await this.$firestore.collection('surveys').doc(this.id).set(survey)
    },
  }
}
</script>
